import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div>
<nav className="navbar navbar-default bootsnav navbar-fixed">
  {/* <div className="navbar-top bg-grey fix">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="navbar-callus text-left sm-text-center">
            <ul className="list-inline" style={{minWidth:"100vw"}}>
              <li><a href style={{fontSize:"0.8rem"}}><i className="fa fa-phone" />MUTHA: <b>8248448831</b></a></li>
              <li><a href style={{fontSize:"0.8rem"}} ><i className="fa fa-phone" />GOWTHAM: <b>9360897509</b></a></li>
              <li><a href style={{fontSize:"0.8rem"}} ><i className="fa fa-phone" />RAJ: <b>9342395054</b></a></li>
              
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="navbar-socail text-right sm-text-center">
            <ul className="list-inline">
            <li><a href="mailto:mgm.services@outlook.com" style={{fontSize:"0.8rem"}} ><i className="fa fa-envelope-o"/> Contact us: <strong>mgm.services@outlook.com</strong></a></li>
              <li><a href><i className="fa fa-facebook" style={{fontSize:"0.8rem"}} /></a></li>
              <li><a href><i className="fa fa-twitter" style={{fontSize:"0.8rem"}} /></a></li>
              <li><a href><i className="fa fa-linkedin" style={{fontSize:"0.8rem"}} /></a></li>
              <li><a href><i className="fa fa-google-plus" style={{fontSize:"0.8rem"}} /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Start Top Search */}
  <div className="top-search">
    <div className="container">
      <div className="input-group">
        <span className="input-group-addon"><i className="fa fa-search" /></span>
        <input type="text" className="form-control" placeholder="Search" />
        <span className="input-group-addon close-search"><i className="fa fa-times" /></span>
      </div>
    </div>
  </div>
  {/* End Top Search */}
  <div className="container"> 
    <div className="attr-nav">
      <ul>
        <li className="search"><a href="#"><i className="fa fa-search" /></a></li>
      </ul>
    </div> 
    {/* Start Header Navigation */}
    <div className="navbar-header">
      <button  className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu" style={{color:"blue",background:"linear-gradient(to left, #247BA0, #247BA0)"}}>
      <i class="fa-solid fa-house fa-xl" style={{color:"#15274b"}}></i>
      </button>
      <a className="navbar-brand">
        <img src="assets/images/logo.png" className="logo logo-scrolled" alt />
      </a>
    </div>
    {/* End Header Navigation */}
    {/* navbar menu */}
    <div className="collapse navbar-collapse" id="navbar-menu">
      <ul className="nav navbar-nav navbar-right">
        <li><Link to="/" style={{color:"#15274b"}}> Home</Link></li>
        <li><Link to="/about" style={{color:"#15274b"}} > About</Link></li>
        <li><Link to="/service" style={{color:"#15274b"}} > Service</Link></li>
        <li><Link to="/jobs" style={{color:"#15274b"}} >Jobs</Link></li>
        <li><Link to="/contact" style={{color:"#15274b"}} > Contact</Link></li>
      </ul>
    </div>{/* /.navbar-collapse */}
  </div> 
</nav>
</div>
  )
}

export default Header