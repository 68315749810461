import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Bussiness from './Bussiness';
import Industries from './Industries';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Features from './Features';
import { useNavigate } from 'react-router-dom';
// import Industries from './Pages/Industries';
// import Services from './Pages/Services';
// import About from './Pages/About';
// import Bussiness from './Pages/Bussiness';
// import Contact from './Pages/Contact';
// import Features from './Pages/Features';

const Home = () => {
  const navigate=useNavigate();
  return (
    <div><section className="home bg-black fix">
  <div className="overlay" />
  <div className="container">
    <div className="row">
      <div className="main_home text-center">
        <div className="col-md-12">
        <Carousel>
        <div className="slid_item" >
              <div className="home_text ">
                <h1 className="text-white">Welcome to MGM Engineering & Consultants</h1>
                <h2 className="text-white">Local Excellence and global reach</h2>
                <h2 className="text-white">The Right person for the Right Job</h2>
              </div>
        </div>
        <div className="slid_item">
              <div className="home_text ">
                <h1 className="text-white">Design & Building<strong>Construction</strong></h1>
                <h2 className="text-white">Kitchen Wardrobe Works</h2>
                <h2 className="text-white">Plumping & Electrical Works</h2>
              </div>
        </div>
        </Carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<Features/>
<About/>
      <Industries/>
      <Services/>
    
      <Contact/>
    </div>
  )
}

export default Home