import React, { useEffect } from 'react'
import Bussiness from './Bussiness'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{marginTop:"200px"}} className='about'>
          <div className="container">
{/* <div className="main_product roomy-80"> */}
      <div className="head_title text-center fix">
        <h1 className="text-uppercase" style={{color:"#15274b"}}>About Us</h1>
      </div>
      <div className="row">
      <div className="">
      <h2 className="text-uppercase" style={{textAlign:"start"}}><b style={{color:"#15274b"}} >MGM Engineering & Consultants</b></h2>
      <b className="text-uppercase" style={{color:"#333"}} >MGM is a leading skill development centre in karaikudi since 2015,we do play a vital role in training right candidate to meet client's requirement. We teaching skills and great success in their lives.</b>
      <ul>
        <li style={{padding:"10px",color:"#333"}}><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i><b>Testing Skills, Attitude and sort listing</b></li>
        <li style={{padding:"10px",color:"#333"}}><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i><b>Educating saftey to meet international standard</b></li>
        <li style={{padding:"10px",color:"#333"}}><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i><b>Sourcing Candidates from various regions in india</b></li>
      </ul>
      </div>
      </div>
  </div>
  <Bussiness/>
  </div>
  
//   </div>

  )
}

export default About