import React from 'react'
import './card.css'

const Features = () => {
  const cards=[
    {
      title:"Best Quality Recruitment Consultancy Services",
      description:"At MGM Consultants, we take pride in delivering top-notch recruitment consultancy services to our clients in Singapore and beyond. Our commitment to excellence sets us apart.",
      src:"assets/images/best.jpeg"
    },
    {
      title:"Tailored Recruitment Solutions",
      description:"MGM Consultants understands the dynamic nature of the job market. We provide recruitment solutions tailored to your needs, ensuring you stay ahead in the competitive hiring landscape.",
      src:"assets/images/tailored.jpg"
    },
    {
      title:"Customizable Recruitment Strategies",
      description:"Our team at MGM Consultants is dedicated to crafting recruitment strategies that suit your unique requirements. We make it easy for you to customize your hiring process for optimal results.",
      src:"assets/images/customaizable.jpg"
    }
    
  ]
  return (
    <div>

  <div className='container' style={{marginBottom:"-200px"}}>
    <div className="row" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <div className=" roomy-70 card-full" >
      
        {cards.map((card)=>{

        return(
        <div className="col-md-4 " style={{marginBottom:"20px"}} >
          <div className="card" style={{width: '85%', height:"350px",borderRadius:"5px"}}>
            <img className="card-img-top img-rounded" src={card.src} style={{height:"150px",width:"100%"}} alt="Foreign jobs consultancy in karaikudi" />
            <div className="card-body" style={{padding:"10px"}}>
              <h5 className="card-title text-white" >{card.title}</h5>
              <b className="card-text">{card.description}</b>
            </div>
          </div>
        </div>)
        })}
      </div>
    </div>
  </div>

    </div>
  )
}

export default Features