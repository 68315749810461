import { Route, Routes} from 'react-router-dom';
import './App.css';
import About from './Pages/About';
import Bussiness from './Pages/Bussiness';
import Contact from './Pages/Contact';
import Features from './Pages/Features';
import Footer from './Pages/Footer';
import Header from './Pages/Header';
import Home from './Pages/Home';
import Industries from './Pages/Industries';
import Services from './Pages/Services';

function App() {
  return (
    <div className='culmn'>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/service" element={<Services/>}/>
        <Route path="/jobs" element={<Industries/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
