import React, { useEffect, useRef, useState } from 'react'
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const[sent,setSent]=useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_rj2bb7s', 'template_sdo9vp2', form.current, '9FGCx2aa9wOxA5A_a')
        .then((result) => {
            console.log(result.text);
            setSent(true)
            setTimeout(() => {
              setSent(false)
            }, 2000);

        }, (error) => {
            console.log(error.text);
            setSent(false)
        });
      };
  return (
    <div style={{marginTop:"100px"}}>
<div className="formbold-main-wrapper about">    
  {/* Author: FormBold Team */}
  {/* Learn More: https://formbold.com */}
  <div className="formbold-form-wrapper about">
    <form ref={form} onSubmit={handleSubmit}>
    <h1 style={{color:"#15274b"}}>Contact Us</h1>
      <div className="formbold-mb-5">
        <label htmlFor="name" className="formbold-form-label"> Full Name </label>
        <input type="text" name="name" id="name" placeholder="Full Name" className="formbold-form-input" required/>
      </div>
      <div className="formbold-mb-5">
        <label htmlFor="phone" className="formbold-form-label"> Phone Number </label>
        <input type="tel" name="phone" id="phone" placeholder="Enter your phone number" className="formbold-form-input"  required/>
      </div>
      <div className="formbold-mb-5">
        <label htmlFor="email" className="formbold-form-label"> Email Address </label>
        <input type="email" name="email" id="email" placeholder="Enter your email" className="formbold-form-input"  required/>
      </div>
      <div className="formbold-mb-5">
        <label htmlFor="email" className="formbold-form-label"> Message </label>
        <textarea name="message" id="email" placeholder="Enter your message" className="formbold-form-input"  required/>
      </div>
      <div className="flex flex-wrap formbold--mx-3">
      </div>
      <div>
        <button className="formbold-btn">SUBMIT</button>
      </div>
      {sent ? (
      <div class="alert alert-success" style={{marginTop:"20px"}} role="alert">
          Email Sent Successfully!
      </div>
      ) : (<></>)}
    </form>
  </div>
</div>


    </div>
  )
}

export default Contact