import React from 'react'

const Bussiness = () => {
  return (
    <div >
                    <section id="business" className="business bg-grey roomy-70 about">
  <div className="container">
    <div className="row">
    <div className="main_business">
        <div className="col-md-12">
        <div className="business_item sm-m-top-50">
            <h1 className="text-uppercase"><strong style={{color:"#15274b"}}>profile</strong></h1>
            <p className="m-top-20" style={{marginBottom:"30px"}}>MGM is a training provider and placement offering staffing services to our clients in singapore and beyond.whether you are a candidate searching for the ideal task.
            Our experience and quality service has picked up to particularly in engineering and technical placement.
            With years of experience and an unwavering commitment to quality service, MGM has earned a strong reputation, especially in the fields of engineering and technical placements. Our team of experts is well-versed in the intricacies of these industries, ensuring that we match the right talent with the right opportunities.

At MGM, we understand the ever-evolving dynamics of the job market, and we are poised to adapt and innovate our services accordingly. Our mission is to facilitate meaningful connections between employers and job seekers, contributing to the growth and success of both parties.

Partner with MGM, and experience the difference in recruitment excellence. We are here to bridge the gap between talent and opportunity, bringing quality staffing solutions to Singapore and beyond.</p>
          </div>
          </div>
          </div>
      <div className="main_business">
      
        <div className="col-md-6" style={{marginBottom:"30px"}}>
          <div className="business_item sm-m-top-50">
            <h2 className="text-uppercase"><strong style={{color:"#15274b"}} >mission</strong></h2>
            <p className="m-top-20">Our mission is to provide a human resourse service which benefit both the employer and the employee and to be a corporate recruiter who is reliable ,  honest and commited. we see ourselves as being a premier global corporate manpower recruitment agency.</p>
            {/* <div className="business_btn" style={{marginBottom:"30px"}}>
              <a className="btn btn-default m-top-20">Read More</a>
              <a href className="btn btn-primary m-top-20" onClick={()=>navigate("/contact")}>Apply</a>
            </div> */}
          </div>
        </div>
        <div className="col-md-6" style={{marginBottom:"30px"}} >
          <div className="business_item sm-m-top-50">
            <h2 className="text-uppercase"><strong style={{color:"#15274b"}} >vision</strong></h2>
            <p className="m-top-20">
Our vision is to lead the industry by pioneering innovative workforce solutions, delivering exceptional services, and empowering our clients to thrive in the evolving world of work. We aspire to be the trusted partner that sets the standard for global corporate manpower recruitment agencies.</p>
            {/* <div className="business_btn" style={{marginBottom:"30px"}}>
              <a className="btn btn-default m-top-20">Read More</a>
              <a href className="btn btn-primary m-top-20" onClick={()=>navigate("/contact")}>Apply</a>
            </div> */}
          </div>
        </div>
       
        <div className="col-md-6">
          <div className="business_item sm-m-top-50">
            <h2 className="text-uppercase"><strong style={{color:"#15274b"}} >Services</strong></h2>
            <p className="m-top-20">
            <ul type="square" >
              <p>Skill development and training.</p>
              <p >Organizing interview</p>
              <p >Recruits manpower on either a permanent basics or a contractual basis</p>
              <p >saftey , Productivity Orientation</p>
              <p >Staffing Services</p>
            </ul>
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="business_item sm-m-top-50">
            <h2 className="text-uppercase"><strong style={{color:"#15274b"}} >Our Successful Stories</strong></h2>
            <p className="m-top-20" style={{fontFamily:"Helvetica ",fontWeight:"bold"}}>MGM Engineering & Consultants has firmly established its expertise across various industry segments, catering to both the fundamental workforce recruitment needs and the intricate demands of clients seeking comprehensive workforce strategy development and implementation.

Our seasoned team at MGM has honed its skills in understanding the diverse requirements of talent acquisition. We are well-versed in sourcing candidates for basic job roles, ensuring a strong foundation for your workforce. Additionally, we excel in addressing the multifaceted challenges posed by clients seeking to craft and execute strategic workforce plans.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Bussiness