import React, { useEffect } from 'react'

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{marginTop:"50px"}}>
        <div className="container">
<div className="main_product roomy-80">
      <div className="head_title text-center fix">
        <h1 className="text-uppercase" style={{color:"#15274b"}} >Our services</h1>
      </div>
      <div className="row">
      <div className="col-lg-6 col-md-6">
        <h2 className="text-uppercase" style={{color:"#15274b"}} >Training Provided For :-</h2>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Pipe Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Steel Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Mechanical Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>General Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Welder(Arc,Tig,Mig)</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Electrician, E&I Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Insulation Fitter</h3>
  </div>
  </div>
  </div>
  </div>
    </div>
  )
}

export default Services