import React, { useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import './card.css'
const Industries = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate=useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{marginTop:"100px"}}>
        <section id="product" className="product">
  <div className="container">
    <div className="main_product roomy-80">
      <div className="head_title text-center fix">
        <h2 className="text-uppercase" style={{color:"#15274b"}} >Industries we Serve</h2>
      </div>
      <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
        {/* Indicators */}

        {/* Wrapper for slides */}
        <div className="carousel-inner" role="listbox">
          <div className="item active">
            <div className="container">
              <div className="row">
                { (!isMobile)?(
                <Carousel autoPlay={true} interval={3000}>
                  <div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/oil.jpg" alt='Karaikudi foreign job agency' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/oil.jpg" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Oil & Gas</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/engineering.png" alt='Karaikudi foreign job agency' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/engineering.png" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Engineering & Construction</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/electrical.jpg" alt='Karaikudi foreign job agency' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/electrical.jpg" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Electrical & Electronics</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/manufacturing.png" alt='Karaikudi foreign job agency' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/manufacturing.png" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Manufacturing & Production</b>
                    </div>
                  </div>
                </div>
                </div>
                <div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/fabrication.png" alt='foreign job agency in karaikudi' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/fabrication.png" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Piping, Fabrication and erection</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/maintainance.png" alt='foreign job agency in karaikudi' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/maintainance.png" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Plant Maintenance</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/steelfabrication.jpg" alt='foreign job agency in karaikudi' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/steelfabrication.jpg" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Structural steel fabrication and erection</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="port_item xs-m-top-30">
                    <div className="port_img">
                      <img src="assets/images/infrastructure.png" alt='foreign job agency in karaikudi' />
                      <div className="port_overlay text-center">
                        <a href="assets/images/infrastructure.png" className="popup-img">+</a>
                      </div>
                    </div>
                    <div className="port_caption m-top-20">
                      <b>Infrastructure</b>
                    </div>
                  </div>
                </div>
                </div>
                </Carousel>):(
                       <Carousel autoPlay={true} interval={2000}>
                      
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/oil.jpg" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/oil.jpg" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Oil & Gas</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/engineering.png" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/engineering.png" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Engineering & Construction</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/electrical.jpg" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/electrical.jpg" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Electrical & Electronics</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/manufacturing.png" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/manufacturing.png" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Manufacturing & Production</b>
                         </div>
                       </div>
                     </div>
                  
                     
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/fabrication.png" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/fabrication.png" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Piping, Fabrication and erection</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/maintainance.png" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/maintainance.png" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Plant Maintenance</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/steelfabrication.jpg" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/steelfabrication.jpg" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Structural steel fabrication and erection</b>
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-3">
                       <div className="port_item xs-m-top-30">
                         <div className="port_img">
                           <img src="assets/images/infrastructure.png" alt='Karaikudi foreign job agency' />
                           <div className="port_overlay text-center">
                             <a href="assets/images/infrastructure.png" className="popup-img">+</a>
                           </div>
                         </div>
                         <div className="port_caption m-top-20">
                           <b>Infrastructure</b>
                         </div>
                       </div>
                     </div>
                     
                     </Carousel>
                )
}
              </div>
            </div>
          </div>
        </div>
        {/* Controls */}
        {/* <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
          <i className="fa fa-angle-left" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
          <i className="fa fa-angle-right" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a> */}
      </div>
    </div>{/* End off row */}
  </div>{/* End off container */}
</section>
<div className="container" style={{marginBottom:"-50px"}}>
<div className="main_product roomy-80" style={{marginTop:"-100px"}}>
      <div className="head_title text-center fix">
        <h2 className="text-uppercase" style={{color:"#15274b"}} >Category of suitable candidates we provide</h2>
      </div>
      <div className="row">
      <div className="col-lg-6 col-md-6">
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Welder-6G (SMAW,GTAW) , 4G(FCAW,GMAW)</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Piper Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Mechanical Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>General Fitter/General Worker</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Rigger & Signal Man</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Painter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Steel Fabricator</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Steel Bender</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Electrician</h3>
  
  </div>
  <div className="col-lg-6 col-md-6">
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Machine Operator</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Maintenance Technician</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Concrete Work</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Driver (low & Heavy)</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Plant Operator</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Design & Draft Man</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Document Controller</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Plumber</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Nurse</h3>
 
  </div>
  </div>
  </div>
  </div>


{/* Current job vacant */}

<div className="container" style={{marginBottom:"-50px"}}>
<div className="main_product roomy-80" style={{marginTop:"-80px"}}>
      <div className="head_title text-center fix">
        <h2 className="text-uppercase" style={{color:"#15274b"}} >Current job vacant</h2>
      </div>
      <div className="row">
      <div className="col-lg-6 col-md-6">
        <h4 style={{paddingRight:"10px", color: "#0000FF"}}>Construction</h4>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Electrician</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Lifting Supervisor</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Rigger & Signal Man</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Safety Co-Ordinator</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Driver</h3>
  </div>
  <div className="col-lg-6 col-md-6">
  <h4 style={{paddingRight:"10px", color: "#0000FF"}}>Petroleum & Chemical</h4>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Piping Fitter & Mech. Fitter</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Lifting Supervisor</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Rigger & Signal Man</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Safety Co-Ordinator</h3>
  <h3><i class="fa-solid fa-hand-point-right" style={{paddingRight:"10px",color: "#0000FF"}}></i>Driver</h3>
 
  </div>
  </div>
  </div>
  </div>

{/* Current job vacant */}

    </div>
  )
}

export default Industries