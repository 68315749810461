import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {
  const navigate=useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
<div>
  <section id="action" className="action bg-primary roomy-40">
    <div className="container">
      <div className="row">
        <div className="maine_action">
          <div className="col-md-8">
            <div className="action_item text-center">
              <h1 className="text-white text-uppercase">PLAN YOUR DREAM JOB</h1>
            </div>
          </div>
          <div className="col-md-4">
            <div className="action_btn text-left sm-text-center">
              <a href className="btn btn-default" onClick={()=>navigate("/contact")}>Apply Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer id="contact" className="footer action-lage bg-black p-top-80">
    {/*<div class="action-lage"></div>*/}
    <div className="container">
      <div className="row">
        <div className="widget_area">
          <div className="col-md-3">
            <div className="widget_item widget_about">
              <h5 className="text-white">About Us</h5>
              <p className="m-top-20 text-white">MGM is a leading skill development centre in karaikudi since 2015,we do play a vital role in training right candidate to meet client's requirement. We teaching skills and great success in their lives.</p>
              <div className="widget_ab_item m-top-30">
                <div className="item_icon"><i className="fa fa-location-arrow" /></div>
                <div className="widget_ab_item_text">
                  <h6 className="text-white">Location</h6>
                  <p className='text-white'>
                    Door No.30/1 Kovilur Rd,<br/>Behind NNS Bus Shed,<br/>Karaikudi-630 001,<br/>Sivagangai Dt</p>
                </div>
              </div>
              <div className="widget_ab_item m-top-30">
                <div className="item_icon"><i className="fa fa-phone" /></div>
                <div className="widget_ab_item_text">
                  <h6 className="text-white">Phone :</h6>
                  <p className='text-white'> 8248448831</p>
                </div>
              </div>
              <div className="widget_ab_item m-top-30">
                <div className="item_icon"><i className="fa fa-envelope-o" /></div>
                <div className="widget_ab_item_text">
                  <h6 className="text-white">Email Address :</h6>
                  <p className='text-white'>mgm.services@outlook.com</p>
                </div>
              </div>
            </div>{/* End off widget item */}
          </div>{/* End off col-md-3 */}
          <div className="col-md-3">
            <div className="widget_item widget_service sm-m-top-50">
              <h5 className="text-white">Latest News</h5>
              <ul className="m-top-20">
                <li className="m-top-20"><Link to="/"> <i className="fa fa-angle-right" /> Home</Link></li>
                <li className="m-top-20"><Link to="/about"> <i className="fa fa-angle-right" /> About</Link></li>
                <li className="m-top-20"><Link to="/service"> <i className="fa fa-angle-right" /> Service</Link></li>
                <li className="m-top-20"><Link to="/jobs"> <i className="fa fa-angle-right" />Jobs</Link></li>
                <li className="m-top-20"><Link to="/contact"> <i className="fa fa-angle-right" onClick={scrollToTop} /> Contact</Link></li>
              </ul>
            </div>{/* End off widget item */}
          </div>{/* End off col-md-3 */}
          <div className="col-md-3">
            <div className="widget_item widget_newsletter sm-m-top-50">
              <h5 className="text-white">Newsletter</h5>
              <form className="form-inline m-top-30">
                <div className="form-group">
                  <input type="email" className="form-control" placeholder="Enter you Email" />
                  <button type="submit" className="btn text-center"><i className="fa fa-arrow-right" /></button>
                </div>
              </form>
              <div className="widget_brand m-top-40">
              <img src="assets/images/logo.png" className="logo logo-scrolled" alt />
              <div style={{marginTop:"-130px"}}>
              <h5 className='text-white'>MGM Engineering & Consultants</h5>
              <p className='text-white'>Elevate your business with our expert consulting services. We bring innovative solutions and tailored strategies to drive your success.</p>
              </div>
              </div>
              <ul className="list-inline m-top-20">
                <li>-  <a href><i className="fa-brands fa-facebook" /></a></li>
                <li><a href><i className="fa-brands fa-twitter" /></a></li>
                <li><a href><i className="fa-brands fa-linkedin" /></a></li>
                <li><a href><i className="fa-brands fa-google-plus" /></a></li>
                <li><a href><i className="fa-brands fa-behance" /></a></li>
                <li><a href><i className="fa-brands fa-dribbble" /></a>  - </li>
              </ul>
            </div>{/* End off widget item */}
          </div>{/* End off col-md-3 */}
        </div>
      </div>
    </div>
    <div className="main_footer fix bg-mega text-center p-top-40 p-bottom-30 m-top-80">
     <p className='text-white'>© 2023 by MGM consultants | Designed by <a href="http://kims.org.in">Kims softwares</a></p>
    </div>
  </footer>
</div>


    </div>
  )
}

export default Footer